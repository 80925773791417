<template>
  <EntitySingle :config="config" :entity.sync="entity" :loaded.sync="entityLoaded">
    <b-tabs
      v-if="entityLoaded"
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
        </template>
        <EntityEditSection
          :module="config.module"
          :entity.sync="entity"
          :form-fields="infoFormFields"
          allow-autofocus
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="MapPinIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t('Address') }}</span>
        </template>
        <EntityEditSection
          :module="config.module"
          :entity.sync="entity"
          :form-fields="addressFormFields"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ImageIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t('Branding Info') }}</span>
        </template>
        <EntityEditSection
          :module="config.module"
          :entity.sync="entity"
          :form-fields="brandingFormFields"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t('Billing Info') }}</span>
        </template>
        <EntityEditSection
          :module="config.module"
          :entity.sync="entity"
          :form-fields="billingFormFields"
        />
      </b-tab>
    </b-tabs>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
// import store from '@/store'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import { translationUtils } from '@core/mixins/ui/translations'
import storeModule from '../VenueStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        enabled: true,
        country: { id: 263, title: 'Greece' },
        entityType: 1,
      },
      entityLoaded: false,
      countryOptions: [],
      entityTypeOptions: [
        { id: 1, title: this.$t('Hotel') },
        { id: 2, title: this.$t('Convention Center') },
      ],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'venues',
        endpoint: 'venues',
        route: 'venues',
        title: {
          single: this.$t('Venue Add'),
          plural: this.$t('Venues'),
        },
      }
    },

    infoFormFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$i18n.t('Title'),
          required: true,
          colSize: 6,
        },
        {
          id: 'entityType',
          type: 'select',
          label: this.$i18n.t('Type'),
          required: true,
          options: this.entityTypeOptions,
          colSize: 6,
        },
        {
          id: 'phone1',
          type: 'text',
          label: this.$i18n.t('phone1'),
          rules: 'phone',
          required: true,
          colSize: 6,
        },
        {
          id: 'email',
          type: 'email',
          label: this.$i18n.t('email'),
          rules: 'email',
          required: true,
          colSize: 6,
        },
        {
          id: 'phone2',
          type: 'text',
          label: this.$i18n.t('phone2'),
          rules: 'phone',
          colSize: 4,
        },
        {
          id: 'phone3',
          type: 'text',
          label: this.$i18n.t('phone3'),
          rules: 'phone',
          colSize: 4,
        },
        {
          id: 'fax',
          type: 'text',
          label: this.$i18n.t('Fax'),
          rules: 'phone',
          colSize: 4,
        },
        {
          id: 'website',
          type: 'text',
          label: this.$t('website'),
          rules: 'url',
          colSize: 6,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
          colSize: 3,
        },
      ]
    },
    addressFormFields() {
      return [
        {
          id: 'country',
          type: 'select',
          object: true,
          label: this.$i18n.t('country'),
          required: true,
          options: this.countryOptions,
          colSize: 6,
        },
        {
          id: 'city',
          type: 'text',
          object: true,
          label: this.$i18n.t('city'),
          required: true,
          colSize: 6,
        },
        {
          id: 'postalCode',
          type: 'text',
          label: this.$t('zip'),
          required: true,
          colSize: 4,
        },
        {
          id: 'address',
          type: 'text',
          label: this.$t('street'),
          required: true,
          colSize: 8,
        },
        {
          id: 'map',
          type: 'map',
          colSize: 12,
        },
      ]
    },
    brandingFormFields() {
      return [
        {
          id: 'logo',
          type: 'cropupload',
          label: `${this.$i18n.t('logo')} (512x512)`,
          colSize: 4,
          width: 512,
          height: 512,
          autoupload: true,
          required: true,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$t('description'),
          colSize: 8,
        },
      ]
    },
    billingFormFields() {
      return [
        {
          id: 'companyName',
          type: 'text',
          label: this.$i18n.t('Company Name'),
          colSize: 6,
        },
        {
          id: 'taxOffice',
          type: 'text',
          label: this.$i18n.t('Tax Office'),
          colSize: 6,
        },
        {
          id: 'vatId',
          type: 'text',
          label: this.$i18n.t('Vat Number'),
          colSize: 6,
        },
        {
          id: 'vat',
          type: 'number',
          label: this.$i18n.t('vat'),
          rules: 'positive',
          colSize: 3,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
  },
}
</script>
